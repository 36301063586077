@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 80%);
  background-repeat: no-repeat;
}

body {
  font-family: "DM Sans", sans-serif;
  background-color: #ffffff;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

.vertical {
  background-color: #ffffff;
}

p {
  margin: 0;
  padding: 0;
}

a {
  cursor: pointer;
  pointer-events: all;
  color: rgb(0, 0, 0);
  text-decoration: none; /* no underline */
}

.c1 {
  column-count: 1;
  margin-left: 35%;
  margin-right: 10%;
  max-width: 1200px;
  padding-top: 20rem;
  font-size: clamp(1.2rem, 0.9204rem + 1.3979vw, 3.157rem);
  font-weight: 500;
  color: #000000;
}

@media screen and (max-width: 1000px) {
  .c1 {
    margin-left: 5%;
    margin-right: 5%;
    padding-top: 16rem;
  }
}

.c1down {
  column-count: 1;
  margin-left: 35%;
  margin-right: 6%;
  margin-top: 20rem;
  margin-bottom: 25rem;
  max-width: 1210px;
  flex: 1 1 0%;
  font-size: clamp(1.1rem, 1.0033rem + 0.4836vw, 1.777rem);
  font-weight: 400;
  color: #000000;
}

@media screen and (max-width: 1000px) {
  .c1down {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 8rem;
    margin-bottom: 20rem;
  }
}

.c2down {
  column-count: 1;
  margin-left: 8%;
  margin-right: 5%;
  padding-top: 1rem;
  padding-bottom: 2rem;
  max-width: 1920px;
  flex: 1 1 0%;
  text-align: left;
  font-size: clamp(1rem, 0.9286rem + 0.3571vw, 1.5rem);
  font-weight: 400;
  color: #000000;
}

@media screen and (max-width: 1000px) {
  .c2down {
    margin-left: 8%;
    margin-right: 8%;
    padding-top: 1rem;
    padding-bottom: 10rem;
  }
}

.c3down {
  column-count: 1;
  margin-left: 2%;
  margin-right: 43%;
  margin-top: 20rem;
  margin-bottom: 1rem;
  max-width: 1200px;
  flex: 1 1 0%;
  font-size: clamp(1.5rem, 1.2633rem + 1.1836vw, 3.157rem);
  font-weight: 500;
}

@media screen and (max-width: 1000px) {
  .c3down {
    margin-left: 5%;
    margin-right: 5%;
    padding-top: 12rem;
  }
}

.infohead {
  font-weight: 500;
}

.portfolio {
  text-align: left;
  display: grid;
  mix-blend-mode: normal;
}

/* Create two unequal columns that floats next to each other */
.column {
  float: left;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 20rem;
  padding-bottom: 25px;
  font-size: clamp(1.2rem, 1.086rem + 0.57vw, 1.77rem);
  font-weight: 400;
}

.left {
  width: 60%;
}

.right {
  width: 40%;
  text-align: right;
}


/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 1000px) {
  .column {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 16rem;
    padding-bottom: 40px;
  }

  .left {
    width: 63%;
  
  }
  
  .right {
    width: 37%;
    text-align: right;
  }

}

h2 {
  font-size: 1.5rem;
  font-weight: 400;
  color: #000000;
}

.faq-header {

  border-bottom: 0px dotted #000000;
  padding: 24px;
  padding-bottom: 2rem;
  max-width: 1920px;
  flex: 1 1 0%;
  font-size: 4vw;
  font-weight: 500;
}



.faq-content {
  margin: 0 auto;
}

.faq-question {
  padding: 0px 0;
  border-bottom: 1px solid #000000;
  background-color: #ffffff;
}

.panel-title {
  color: #000000;
  font-size: clamp(2.7rem, 2.3714rem + 1.6429vw, 5rem);
  font-weight: 500;
  width: 100%;
  position: relative;
  margin: 0;
  padding: 10px 0px;
  padding-left: 8%;
  display: block;
  cursor: pointer;
}

.panel-title:hover {
  background-color: #000000;
  color: #ffffff;
}

.panel-content {
  width: 70%;
  padding: 0px 0px;
  margin: 0 40px;
  height: 0;
  overflow: hidden;
  position: relative;
  opacity: 0;
  -webkit-transition: .4s ease;
  -moz-transition: .4s ease;
  -o-transition: .4s ease;
  transition: .4s ease;
}

.panel:checked ~ .panel-content{
  height: auto;
  opacity: 1;
  padding: 14px;
}

@media screen and (max-width: 1000px) {
  .panel-content {
    width: 100%;
    margin: 0 0px;
  }
}

.plus {
  position: absolute;
  margin-left: 20px;
  margin-top: 4px;
  z-index: 5;
  font-size: 42px;
  line-height: 100%;
  -webkit-user-select: none;    
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transition: .2s ease;
  -moz-transition: .2s ease;
  -o-transition: .2s ease;
  transition: .2s ease;
}

.panel:checked ~ .plus {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.panel {
  display: none;
}

.faqzusatz {
  margin-left: 2%;
  font-size: clamp(1rem, 0.9857rem + 0.0714vw, 1.1rem);
  font-weight: 400;
}

@media screen and (max-width: 1000px) {
  .faqzusatz {
    margin-left: 5%;
    margin-right: 5%;
    font-size : 0px;
  }
}

::-moz-selection { /* Code for Firefox */
  color: rgb(255, 255, 255);
  background: rgb(0, 0, 0);
}

::selection {
  color: rgb(255, 255, 255);
  background: rgb(0, 0, 0);
}



* {box-sizing: border-box}
.mySlides1, .mySlides2, .mySlides3, .mySlides4, .mySlides5 {display: none}
img {vertical-align: middle;}

/* Slideshow container */
.slideshow-container {
  max-width: 1750px;
  position: relative;
  margin: auto;
}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: rgb(255, 255, 255);
  font-weight: normal;
  font-size: clamp(0.4rem, 0.8rem + 0.4836vw, 1.2rem);
  mix-blend-mode: exclusion;
  transition: 0.6s ease;
  border-radius: 0 0px 0px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 0px 0 0 0px;
}